<template>
  <div class="cw-main-window">
    <v-toolbar
      v-for="bar in bars"
      :id="bar.id"
      :key="bar.id"
      :class="[bar.cssClass, { 'active' : toolbar.fixed}]"
      class="cw-toolbar__main"
    >
      <div class="cw-container align-center layout row">
        <v-toolbar-title class="align-center layout row">
          <component :is="logo" class="cw-main-window__logo"/>
          <v-divider class="ml-4 hidden-sm-and-down" vertical/>
          <div class="align-bottom text-subtitle-1 hidden-sm-and-down ml-4">
            <translate>
              Loan application
            </translate>
          </div>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn
          id="updateCookieConsent"
          icon
          @click="
            updateCookieConsent();
            $eventLogger.clickEvent($event);
          "
        >
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12,3A9,9 0 0,0 3,12A9,9 0 0,0 12,21A9,9 0 0,0 21,12C21,11.5 20.96,11 20.87,
              10.5C20.6,10 20,10 20,10H18V9C18,8 17,8 17,8H15V7C15,6 14,6 14,6H13V4C13,3 12,
              3 12,3M9.5,6A1.5,1.5 0 0,1 11,7.5A1.5,1.5 0 0,1 9.5,9A1.5,1.5 0 0,1 8,7.5A1.5,
              1.5 0 0,1 9.5,6M6.5,10A1.5,1.5 0 0,1 8,11.5A1.5,1.5 0 0,1 6.5,13A1.5,1.5 0 0,
              1 5,11.5A1.5,1.5 0 0,1 6.5,10M11.5,11A1.5,1.5 0 0,1 13,12.5A1.5,1.5 0 0,1 11.5,
              14A1.5,1.5 0 0,1 10,12.5A1.5,1.5 0 0,1 11.5,11M16.5,13A1.5,1.5 0 0,1 18,14.5A1.5,
              1.5 0 0,1 16.5,16H16.5A1.5,1.5 0 0,1 15,14.5H15A1.5,1.5 0 0,1 16.5,13M11,16A1.5,
              1.5 0 0,1 12.5,17.5A1.5,1.5 0 0,1 11,19A1.5,1.5 0 0,1 9.5,17.5A1.5,1.5 0 0,1 11,16Z"
            />
          </svg>
        </v-btn>
        <template v-if="showChat">
          <v-btn
            id="giosg"
            icon
            @click="$eventLogger.clickEvent($event)"
          >
            <v-icon>
              support_agent
            </v-icon>
          </v-btn>
        </template>
      </div>
    </v-toolbar>
    <main>
      <v-container class="cw-container pa-0">
        <transition
          mode="out-in"
          name="fade"
          appear
        >
          <router-view/>
        </transition>
      </v-container>
    </main>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'CwDashboard',

  data: () => ({
    bars: [{
      id: 'cw-toolbar',
      cssClass: 'cw-toolbar__main elevation-0 transparent',
      dark: false,
      modifier: 'main',
    }, {
      id: 'cw-toolbar-clone',
      cssClass: 'cw-toolbar__main--clone white',
      dark: true,
      modifier: 'floating',
    }],
    right: null,
    scrollPosition: 0,
    toolbar: {
      transparent: false,
      fixed: false,
    },
    showChat: (window.CookieInformation && window.CookieInformation.getConsentGivenFor('cookie_cat_functional')) || false,
  }),

  computed: {
    ...mapGetters({
      product: 'application/getProduct',
    }),

    logo() {
      if (!this.product || !this.product.brand) return '';
      return () => import('@shared/assets/images/logos/' + this.product.brand.toLowerCase() + '.svg'); // eslint-disable-line
    },
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler);
  },

  mounted() {
    /**
     * Add an event listener to handle the navbar visibility on scroll.
     */
    window.addEventListener('scroll', this.scrollHandler, {
      capture: true,
      passive: true,
    });

    const cookieConsentButtons = document.querySelectorAll('.coi-banner__accept');

    cookieConsentButtons.forEach(button => button
      .addEventListener('click', this.toggleChat, {
        passive: true,
      }));
  },

  methods: {
    ...mapActions({
      addNotification: 'notifications/addNotification',
    }),

    toggleChat() {
      this.showChat = !!window.CookieInformation.getConsentGivenFor('cookie_cat_functional');
    },

    /**
     * Handles the showing and hiding of the floating navigation and closing the menus
     * based on the scroll position and direction.
     */
    scrollHandler() {
      const yPos = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      // set heroHeight as 64 even if there is no heroElement.
      const heroHeight = 64;
      const scrollDifference = this.scrollPosition - yPos;

      // going up fast enough (more than 15px since last check).
      if (scrollDifference >= 15 && !this.transparentHeader) {
        // show floating navigation if current position is below the center of the hero height.
        this.toolbar.fixed = yPos > heroHeight / 2;
      // going down fast enough and current position is above the center of the hero height.
      } else if (scrollDifference <= -15 || yPos < heroHeight / 2) {
        // hide floating navigation and close open menus.
        this.toolbar.fixed = false;
      }
      this.scrollPosition = yPos;
    },

    updateCookieConsent() {
      window.CookieConsent.renew();
    },
  },
};
</script>

<style lang="scss">
.fake-button {
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  margin: 8px;
  width: 36px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.cw-main-window {
  background-color: palette(Grey, 100);
  min-height: 100vh;

  &__logo {
    height: 24px;
  }
}

.cw-container {
  margin: 0 auto !important;
  max-width: 1240px !important;
}

.v-toolbar.cw-toolbar {
  &__main {
    z-index: 1;

    .v-toolbar__content {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &--clone {
      background-color: #fff;
      color: $black_87;
      left: 0;
      position: fixed;
      top: 0;
      transform: translateY(-88px) !important;
      z-index: 4;

      &.active {
        transform: translateY(0) !important;
      }

      .v-icon {
        color: $black_54;
      }
    }

    .cw-container {
      @include mq($until: sm) {
        padding: 0 8px;
      }

      @include mq($from: sm) {
        padding: 0 16px;
      }
    }
  }
}

.page-enter-active,
.page-leave-active {
  transition: all 0.25s ease-out;
}

.page-enter,
.page-leave-active {
  opacity: 0;
  transform: scale(0.95);
  transform-origin: 50% 50%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: scale(1.02);
  transform-origin: 50% 50%;
}
</style>
